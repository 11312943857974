import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { BrandContext } from 'src/context/brand-context';
import { addressEU, companyName, VATNumber } from 'src/helpers/brand-helper';
import { getColorScheme } from 'src/styling/colors/colors';
import { smallMobileThreshold } from 'src/styling/customStyles';

export const CopyrightFooter: React.FC<{}> = ({}) => {
  const { brand } = useContext(BrandContext);

  const copyrightStyle = css`
    color: ${getColorScheme().policyText};
    background-color: ${getColorScheme().footerBackground};
    font-weight: lighter;

    @media (max-width: ${smallMobileThreshold}) {
      font-size: 10px;
    }

    div {
      font-weight: bold;
      margin-bottom: 2px;
      padding: 10px;
      padding-bottom: 0px;
      letter-spacing: 0.5px;
    }

    p {
      padding: 10px;
      margin: 0;
    }
  `;

  return (
    <div css={copyrightStyle}>
      <div>Copyright 2021 {companyName[brand]}</div>
      <p>
        VAT No. {VATNumber[brand]}. <br />
        {addressEU[brand]}
      </p>
    </div>
  );
};

export default CopyrightFooter;
