import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { LanguageContext } from 'src/context/language-context';
import { staticTextLookupByLanguage } from 'src/translators/static-text-translator';
import CopyrightFooter from './copyright-footer';
import { BrandContext } from 'src/context/brand-context';
import { largeMobileThreshold, mediumMobileThreshold } from 'src/styling/customStyles';
import { getColorScheme } from 'src/styling/colors/colors';
import { brandPoliciesConfig } from 'src/helpers/brand-helper';
import { Language } from 'src/shared/enums/language';
import ContactInfoFooter from './contact-info-footer';

export const Footer: React.FC<{
  toggleSecurityPopup: () => void;
  toggleRefundPopup: () => void;
}> = ({ toggleSecurityPopup, toggleRefundPopup }) => {
  const { language } = useContext(LanguageContext);
  const { brand } = useContext(BrandContext);

  const footerWrapper = css`
    display: flex;
    justify-content: space-around;
    border-top: ${getColorScheme().policyFooter} solid 1.5px;
    border-bottom: ${getColorScheme().policyFooter} solid 1.5px;
    background-color: ${getColorScheme().footerBackground};
    font-size: ${language === Language.DE_DE ? '10px' : '11px'};
    align-items: center;

    div {
      text-transform: uppercase;
      color: ${getColorScheme().policyText};
      text-align: center;
      margin: 5px;
      padding: 6px 2px;
      vertical-align: center;

      // German footer must be resized because of longer translation copies

      @media (max-width: ${largeMobileThreshold}) {
        font-size: ${language === Language.DE_DE ? '7px' : '10px'};
        margin: 5px ${language === Language.DE_DE ? '2px' : '5px'};
      }

      @media (min-width: ${mediumMobileThreshold}) {
        padding: 12px 2px;
        font-size: ${language === Language.DE_DE ? '10px' : '13px'};
      }

      a {
        text-decoration: none;
        color: inherit;
        cursor: pointer;

        &:hover,
        :active {
          color: ${getColorScheme().highlight};
        }
      }
    }
  `;

  return brand && language ? (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <ContactInfoFooter />
      <div css={footerWrapper}>
        <div>
          <a href={brandPoliciesConfig[brand].privacyPolicyUrl} target="_blank">
            {staticTextLookupByLanguage[language].privacyPolicy}
          </a>
        </div>
        <div>
          <a target="_blank" onClick={toggleSecurityPopup}>
            {staticTextLookupByLanguage[language].securityPolicy}
          </a>
        </div>
        <div>
          <a target="_blank" onClick={toggleRefundPopup}>
            {staticTextLookupByLanguage[language].refundPolicy}
          </a>
        </div>
        <div>
          <a href={brandPoliciesConfig[brand].termsAndConditionsUrl} target="_blank">
            {staticTextLookupByLanguage[language].termsAndConditions}
          </a>
        </div>
      </div>
      <CopyrightFooter />
    </div>
  ) : (
    <></>
  );
};

export default Footer;
