import React from 'react';

export type UserType = {
  membershipNumber: string;
  surname: string;
};

export type UserConfig = {
  currentUser: UserType;
  updateCurrentUser: (user: UserType) => void;
  logout: () => void;
};

export const UserDefault: UserType = {
  membershipNumber: '',
  surname: '',
};

export const UserContext = React.createContext<UserConfig>({
  currentUser: UserDefault,
  updateCurrentUser: () => {},
  logout: () => {},
});
