import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: 'No tens saldo pendent al teu compte.',
  waitingForLoginDetailsCheck: 'Comprovant les seves dades, si us plau, esperi...',
  loginNotFound: 'Ho sentim, no trobem informació sobre el compte proporcionat.',
  checkDetailsTryAgain: 'Si us plau, consulta les teves dades abans de tornar-ho a provar.',
  preparingPaymentLoadingMessage: 'Preparant el pagament, si us plau, esperi ...',
  updateRecurringLoadingMessage: 'Preparant els seus pagaments periòdics, si us plau, esperi ...',
  paymentAccepted: 'Se li ha enviat un rebut per correu electrònic.',
  recurringAccepted: 'Li hem enviat un correu electrònic de confirmació',
  welcomeBannerHeader: 'Pagament en Línia',
  hi: 'Hola',
  balanceOf: 'Te',
  onAccount: 'pendents de pagament en el seu compte.',
  payNowButtonText: 'Pagar ara',
  logoutButtonText: 'Tancar sessió',
  loginButtonText: 'Iniciar',
  membershipNumberField: 'Número de soci*',
  surnameField: 'Cognoms*',
  saveSettings: 'Guardar configuració',
  recurringQuestion: 'Configurar el pagament amb una targeta habitual?',
  recurringMessage:
    "Marqueu aquesta casella si està conforme que David Lloyd Clubs utilitzi de nou aquesta targeta en el futur per cobrar qualsevol pagament pendent després del dia 5 del mes i en cas de no disposar d'un pagament automàtic actiu. Podrà canviar de parer en qualsevol moment desmarcant aquesta casella o posant-se en contacte a través del número inferior.",
  recurringMessageNoExistingDebt:
    "Marca aquesta casella si hi estàs d'acord amb què David Lloyd Clubs carregui el pagament en aquesta targeta si no es carregar al teu compte el dia 5 de cada mes o si no tens un mètode de pagament actiu. Pots canviar d'opinió en qualsevol moment desmarcant aquesta casella o posant-te en contacte a través del següent telèfon.",
  waitingForUpdateCheck: 'Actualitzant la seva configuració, si us plau, esperi...',
  settingsUpdateSuccess: "La configuració s'ha actualitzat correctament",
  settingsUpdatedPleaseWait: "Aquests canvis poden tardar uns minuts en veure's al portal.",
  doNotTryUpdateAgain: 'Si us plau, no intentis tornar a canviar la configuració de nou.',
  loginFormHeader: 'Iniciar sessió en el portal de pagament',
  processingPaymentLoadingMessage: "El seu pagament s'està processant...",
  processingRecurringSettingsLoadingMessage:
    'Creant els seus pagaments periòdics, si us plau, esperi...',
  thankYou: 'Gràcies',
  thankYouForPayment: 'Gràcies pel seu pagament',
  thankYouForRecurring: 'Gràcies per crear els seus pagaments periòdics',
  somethingWrongWithPayment: 'Hi ha hagut un error amb el seu pagament.',
  pleaseCheckCardDetails: 'Comprovi les dades de la seva targeta i torni a intentar-lo.',
  tryAgain: 'Torni a intentar-lo',
  errorMessagePayment: 'Hi ha hagut un error en processar el seu pagament.',
  logOutTryAgain: "Torni a la pàgina d'inici per iniciar de nou el procés de pagament.",
  privacyPolicy: 'Política de privacitat',
  securityPolicy: 'Seguretat',
  refundPolicy: 'Política de reemborsaments',
  termsAndConditions: 'Condicions',
  saveSettingsDynamicButtonText: 'Guardar i ',
  error404: '404 Error',
  pageNotFoundHeader: 'Pàgina no trobada',
  pageNotFoundMessage: 'Ho sentim però no hem trobat la pàgina que ha sol·licitat.',
  notFoundTryAgain: "Torni a la pàgina d'inici per iniciar de nou el procés de pagament.",
  home: 'Inici',
  close: 'Tancar',
  securityPopupMessage1:
    "A David Lloyd Leisure, ens comprometem a protegir la seguretat i confidencialitat de les teves dades a través d'un entorn segur a les transaccions.",
  securityPopupMessage2:
    'La nostra pàgina web fa servir la tecnologia Secure Sockets Layer (SSL) estàndard per a encriptar la informació personal i, com pots esperar, ens adherim als estàndars internacionals de seguretat de dades PCI (payments card industry)',
  securityPopupMessage3:
    "A més, tenim seguretat 3D addicional per a totes les transaccions. 3D Secure actúa com un Chip&Pin i protegeix el titular de la targeta demanant una contrassenya secreta. Això permet a la entitat bancària autentificar completament els titular de la targeta durant la transacció online, reduïnt la possibilitat d'usos fraudulents.",
  securityPopupMessage4:
    "Fem tot el que podem per tal de mantenir les dades de la teva transacció i del teu pagament segurs, però en cas que no hi hagi negligència per la nostra part, no podem fer-nos responsables de cap pèrdua que puguis patir si un tercer obté accès no autoritzat a qualsevol dada que proporcionis en accedir o realitzar una transacció des d'aquest web.",
  securityPopupMessage5:
    "És important que et protegeixis davant els accessos no autoritzats a la teva contrassenya i usuari. Assegura't de tancar la sessió quan acabis d'utilitzar un ordinador compartit.",
  refundPolicyMessage1:
    "Les devolucions s'emetran segons els termes i condicions del teu contracte, excepte que es tracti d'un pagament duplicat, que es retornarà completament.",
  refundPolicyMessage2:
    'Les devolucions es processaran en un plaç de 5 dies laborables des de la notificació del pagament duplicat i es faran al teu compte mitjançant transferència bancària.',
  languagePickerMessage: 'Esculli el seu idioma aquí',
  contactHeader: 'Administració',
};
