import React from 'react';
import { Brand } from 'src/shared/enums/brand';

export type BrandConfig = {
  brand: Brand;
  updateBrand: (brand: Brand) => void;
};

export const BrandContext = React.createContext<BrandConfig>({
  brand: Brand.DL_UK,
  updateBrand: () => {},
});
