import { css } from '@emotion/react';
import React from 'react';
import { getColorScheme } from 'src/styling/colors/colors';

export const PolicyCloseButton: React.FC<{ togglePopup: () => void }> = ({ togglePopup }) => {
  const popupCloseButtonStyle = css`
    height: 25px;
    width: 25px;
    // position: absolute;
    align-self: flex-end;
    top: 16px;
    right: 16px;
    cursor: pointer;
  `;

  return (
    <div css={popupCloseButtonStyle} onClick={togglePopup}>
      <svg
        css={{ display: 'block' }}
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Close</title>
        <g id="Atoms/Icons/Close" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon
            id="Fill-3"
            fill={getColorScheme().uiElement}
            points="18.9999989 6.40999942 17.5899989 4.9999997 11.9999993 10.5899996 6.40999942 4.9999997 4.9999997 6.40999942 10.5899996 11.9999993 4.9999997 17.5899989 6.40999942 18.9999989 11.9999993 13.409999 17.5899989 18.9999989 18.9999989 17.5899989 13.409999 11.9999993"
          ></polygon>
        </g>
      </svg>
    </div>
  );
};

export default PolicyCloseButton;
