export enum Brand {
  DL_UK = 'dl-uk',
  DL_IRELAND = 'dl-ireland',
  DL_FRANCE = 'dl-france',
  DL_GERMANY = 'dl-germany',
  DL_ITALY = 'dl-italy',
  DL_NETHERLANDS = 'dl-netherlands',
  DL_SWITZERLAND = 'dl-switzerland',
  DL_BELGIUM = 'dl-belgium',
  DL_SPAIN = 'dl-spain',
  MERIDIAN = 'meridian',
  HARBOUR = 'harbour',
}
