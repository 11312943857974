import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: "Non c'è nessun pagamento in sospenso sul tuo account.",
  waitingForLoginDetailsCheck: 'Controllo dei dati in corso... attendere...',
  loginNotFound:
    "Sfortunatamente non riusciamo a darti ulteriori informazioni in merito all'account indicato.",
  checkDetailsTryAgain: 'Per favore controlla i tuoi dati prima di riprovare.',
  preparingPaymentLoadingMessage: 'Preparazione del pagamento in corso... attendere...',
  updateRecurringLoadingMessage: 'Preparazione del pagamento ricorrente in corso... attendere...',
  paymentAccepted: 'Ti abbiamo inviato un’email di conferma',
  recurringAccepted: 'A confirmation email has been sent to you',
  welcomeBannerHeader: 'Pagamento online',
  hi: 'Ciao',
  balanceOf: 'Una quota di',
  onAccount: 'non saldata è presente nel tuo account.',
  payNowButtonText: 'Paga Subito',
  logoutButtonText: 'Disconnetti',
  loginButtonText: 'Accedi',
  membershipNumberField: 'Numero della membership*',
  surnameField: 'Cognome*',
  saveSettings: 'Salva impostazioni',
  recurringQuestion: 'Vuoi impostare un pagamento ricorrente con carta di credito?',
  recurringMessage:
    'Spunta questa casella se sei d’accordo che David Lloyd Clubs accetti di nuovo il pagamento tramite questa carta in futuro se il tuo account rimane non saldato dopo il 5 di ciascun mese e non hai un metodo di pagamento attivo. Puoi cambiare idea in qualsiasi momento deselezionando questa casella o chiamando il numero sottostante.',
  recurringMessageNoExistingDebt:
    'Clicca questa casella se desideri autorizzare David Lloyd a gestire i pagamenti futuri tramite questa carta di credito se la rata mensile non viene saldata entro il 5 del mese e non esisto altre forme di pagamento attive. Puoi cambiare idea in qualsiasi momento rimuovendo il flag dalla casella oppure contattando il numero di telefono che trovi di seguito.',
  waitingForUpdateCheck: 'Aggiornamento delle impostazioni, attendere prego...',
  settingsUpdateSuccess: 'Le tue impostazioni sono state aggiornate.',
  settingsUpdatedPleaseWait:
    'Ti preghiamo di attendere qualche minuto. Stiamo aggiornando le modifiche sul portale.',
  doNotTryUpdateAgain: 'Per favore, non modificare ulteriormente queste impostazioni.',
  loginFormHeader: 'Accedi al portale di pagamento',
  processingPaymentLoadingMessage: 'Il tuo pagamento è ora in fase di elaborazione...',
  processingRecurringSettingsLoadingMessage:
    'Configurazione del pagamento ricorrente in corso... attendere...',
  thankYou: 'Grazie',
  thankYouForPayment: 'Grazie per il pagamento',
  thankYouForRecurring: 'Grazie per aver istituito un pagamento ricorrente',
  somethingWrongWithPayment: 'Qualcosa non ha funzionato con il tuo pagamento.',
  pleaseCheckCardDetails: 'Controlla i dettagli della tua carta e riprova.',
  tryAgain: 'Riprova',
  errorMessagePayment: 'Errore durante l’elaborazione del pagamento.',
  logOutTryAgain: 'Ritorna alla pagina principale per riavviare la procedura di pagamento.',
  privacyPolicy: 'Informativa sulla privacy',
  securityPolicy: 'Sicurezza',
  refundPolicy: 'Politica di rimborso',
  termsAndConditions: 'Termini e condizioni',
  saveSettingsDynamicButtonText: 'Salva e ',
  error404: '404 Errore',
  pageNotFoundHeader: 'Pagina non trovata',
  pageNotFoundMessage: 'Siamo spiacenti, ma la pagina richiesta non è stata trovata.',
  notFoundTryAgain: 'Ritorna alla pagina principale per riavviare la procedura di pagamento.',
  home: 'Pagina principale',
  close: 'Chiudi',
  securityPopupMessage1:
    'David Lloyd Leisure si impegna a proteggere la sicurezza e la riservatezza delle tue informazioni, fornendoti un ambiente di transazione sicuro.',
  securityPopupMessage2:
    'Il nostro sito Web utilizza la tecnologia Secure Sockets Layer (SSL) standard del settore per fornire la crittografia delle informazioni personali e, come ci si aspetterebbe, aderiamo agli standard internazionali di conformità PCI (industria delle carte di pagamento) per la sicurezza dei tuoi dati personali.',
  securityPopupMessage3:
    "Abbiamo anche una sicurezza 3D aggiuntiva che viene utilizzata per tutte le transazioni. 3D Secure agisce come un Chip & Pin e protegge il titolare della carta chiedendo una password segreta. Ciò consente all'emittente della carta di autenticare completamente i titolari della carta durante la transazione online; riducendo così la probabilità di utilizzo improprio.",
  securityPopupMessage4:
    "Adotteremo ogni mezzo e cura possibile, nella misura in cui è in nostro potere farlo, per mantenere sicuri i dettagli del tuo ordine e pagamento, ma in assenza di negligenza da parte nostra non possiamo essere ritenuti responsabili per eventuali perdite che potresti subire se una terza parte si procura l'accesso non autorizzato ai dati forniti dall'utente durante l'accesso o l'ordinazione da questo sito Web.",
  securityPopupMessage5:
    "È importante che tu ti protegga dall'accesso non autorizzato alla tua password e al tuo computer. Assicurati di aver effettuato il log out quando finisci di utilizzare un computer condiviso.",
  refundPolicyMessage1:
    "I rimborsi verranno emessi come da termini e condizioni dell'iscrizione a meno che non ci sia stato un doppio pagamento, in questo caso si procederà ad un rimborso completo.",
  refundPolicyMessage2:
    'I rimborsi verranno elaborati entro 5 giorni lavorativi dalla notifica di un doppio pagamento e saranno effettuati tramite bonifico bancario sul tuo conto corrente.',
  languagePickerMessage: 'Seleziona la lingua qui',
  contactHeader: 'Amministrazione',
};
