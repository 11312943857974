import React, { useContext } from 'react';
import { StaticTextLookupKey, StaticTextLookup } from 'src/translators/static-text-lookup';
import { staticTextLookup as deDeStaticTextLookup } from 'src/translators/static-text/de-de';
import { staticTextLookup as enGbStaticTextLookup } from 'src/translators/static-text/en-gb';
import { staticTextLookup as esEsStaticTextLookup } from 'src/translators/static-text/es-es';
import { staticTextLookup as caEsStaticTextLookup } from 'src/translators/static-text/ca-es';
import { staticTextLookup as itItStaticTextLookup } from 'src/translators/static-text/it-it';
import { staticTextLookup as frBeStaticTextLookup } from 'src/translators/static-text/fr-be';
import { staticTextLookup as nlBeStaticTextLookup } from 'src/translators/static-text/nl-be';
import { Language } from 'src/shared/enums/language';
import { LanguageContext } from 'src/context/language-context';

export const staticTextLookupByLanguage = {
  [Language.EN_GB]: enGbStaticTextLookup,
  [Language.ES_ES]: esEsStaticTextLookup,
  [Language.CA_ES]: caEsStaticTextLookup,
  [Language.NL_BE]: nlBeStaticTextLookup,
  [Language.FR_BE]: frBeStaticTextLookup,
  [Language.IT_IT]: itItStaticTextLookup,
  [Language.DE_DE]: deDeStaticTextLookup,
};

export const fallbackLanguage: Language = Language.EN_GB;
const fallbackStaticTextLookup: StaticTextLookup = staticTextLookupByLanguage[fallbackLanguage];

export const translateStaticTextIntoLanguage = (
  key: StaticTextLookupKey,
  language: Language = fallbackLanguage
): string => {
  const textInCurrentLanguage = staticTextLookupByLanguage[language][key];

  if (textInCurrentLanguage) {
    return textInCurrentLanguage;
  }

  console.warn(`No translation found for ${key} in the languge ${language}`);

  return fallbackStaticTextLookup[key];
};

export const translateStaticTextFromKeyStringWithFixedValues = (
  key: StaticTextLookupKey,
  params: Record<string, string>,
  language: Language
): string => {
  let translatedText = translateStaticTextIntoLanguage(key, language);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      // "g" flag is necessary to ensure all instances are replaced, not just the first
      const regEx = new RegExp('%' + key + '%', 'g');
      translatedText = translatedText.replace(regEx, params[key]);
    }
  }

  return translatedText;
};

type TranslatedStaticTextProps = {
  lookupKey: StaticTextLookupKey;
  params?: Record<string, string>;
};

export const TranslatedStaticText = ({ lookupKey, params }: TranslatedStaticTextProps) => {
  const { language } = useContext(LanguageContext);
  return (
    <>
      {params
        ? translateStaticTextFromKeyStringWithFixedValues(lookupKey, params, language)
        : translateStaticTextIntoLanguage(lookupKey, language)}
    </>
  );
};
