import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { LanguageContext } from 'src/context/language-context';
import {
  largeMobileThreshold,
  policyMessageStyle,
  popupWindowHeaderStyle,
  popupWrapperStyle,
} from 'src/styling/customStyles';
import { zIndexes } from 'src/styling/zIndexes';
import { staticTextLookupByLanguage } from 'src/translators/static-text-translator';
import PolicyCloseButton from './policy-close-button';

type PolicyType = 'securityPolicy' | 'refundPolicy';

export const PolicyPopup: React.FC<{
  showPopup: boolean;
  togglePopup: () => void;
  policyType: PolicyType;
}> = ({ showPopup, togglePopup, policyType }) => {
  const { language } = useContext(LanguageContext);

  const popupWindowStyle = css`
    background-color: white;
    position: absolute;
    width: 90%;
    height: ${policyType === 'securityPolicy' ? '60%' : '45%'};
    margin: 0 auto;
    z-index: ${zIndexes.popup};
    top: ${policyType === 'securityPolicy' ? '15%' : '20%'};
    left: 0;
    right: 0;
    max-width: 600px;
    padding: 0 16px 16px 16px;
    text-align: center;
    overflow-y: scroll;

    @media (min-width: ${largeMobileThreshold}) {
      padding: 0 32px 32px 32px;
      font-size: 15px;
      height: ${policyType === 'securityPolicy' ? '55%' : '35%'};
      top: ${policyType === 'securityPolicy' ? '15%' : '25%'};
    }
  `;

  const policyMessage =
    policyType === 'securityPolicy' ? (
      <>
        <p css={policyMessageStyle}>{staticTextLookupByLanguage[language].securityPopupMessage1}</p>
        <p>{staticTextLookupByLanguage[language].securityPopupMessage2}</p>
        <p>{staticTextLookupByLanguage[language].securityPopupMessage3}</p>
        <p>{staticTextLookupByLanguage[language].securityPopupMessage4}</p>
        <p>{staticTextLookupByLanguage[language].securityPopupMessage5}</p>
      </>
    ) : (
      <>
        <p css={policyMessageStyle}>{staticTextLookupByLanguage[language].refundPolicyMessage1}</p>
        <p>{staticTextLookupByLanguage[language].refundPolicyMessage2}</p>
      </>
    );

  return (
    showPopup && (
      <div>
        <div onClick={togglePopup} css={popupWrapperStyle} />
        <div css={popupWindowStyle}>
          <div css={popupWindowHeaderStyle}>
            <PolicyCloseButton togglePopup={togglePopup} />
            <div>
              <h3 css={{ margin: '5px 0' }}>{staticTextLookupByLanguage[language][policyType]}</h3>
            </div>
          </div>
          {policyMessage}
        </div>
      </div>
    )
  );
};
