import React, { useState } from 'react';
import { Brand } from 'src/shared/enums/brand';
import { BrandContext } from './brand-context';

type BrandProviderProps = {
  children: React.ReactChild;
};

const BrandProvider: React.FC<BrandProviderProps> = ({ children }) => {
  const [brand, setBrand] = useState<Brand>();

  const updateBrand = (newBrand: Brand) => {
    setBrand(newBrand);
  };

  return (
    <BrandContext.Provider
      value={{
        brand,
        updateBrand,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export default BrandProvider;
