import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: 'Es gibt keinen offenen Saldo auf Ihrem Konto.',
  waitingForLoginDetailsCheck: 'Ihre Angaben werden überprüft, bitte warten...',
  loginNotFound: 'Leider konnten wir keine Informationen zu dem angegebenen Konto finden.',
  checkDetailsTryAgain: 'Bitte überprüfen Sie Ihre Angaben, bevor Sie es erneut versuchen.',
  preparingPaymentLoadingMessage: 'Zahlung wird vorbereitet, bitte warten...',
  updateRecurringLoadingMessage:
    'Einrichtung der regelmäßigen Zahlungsmethode wird vorbereitet, bitte warten...',
  paymentAccepted: 'Eine E-Mail-Bestätigung wurde an Sie gesandt.',
  recurringAccepted: 'Eine Bestätigungs-E-Mail wurde an Sie geschickt',
  welcomeBannerHeader: 'Online-Zahlung',
  hi: 'Hallo',
  balanceOf: 'Sie haben einen ausstehenden Betrag von',
  onAccount: 'für Ihren Account',
  payNowButtonText: 'Jetzt zahlen',
  logoutButtonText: 'Ausloggen',
  loginButtonText: 'Einloggen',
  membershipNumberField: 'Mitgliedsnummer*',
  surnameField: 'Nachname*',
  saveSettings: 'Einstellungen speichern',
  recurringQuestion: 'Wiederkehrende Kartenzahlung einrichten?',
  recurringMessage:
    'Bitte markieren Sie dieses Kästchen, wenn Sie damit einverstanden sind, dass David Lloyds Clubs diese Karte in Zukunft für die Zahlung verwenden darf, falls Ihr Account bis nach dem 5. jedes Monats nicht bezahlt wird und Sie über keine aktive Zahlungsmethode verfügen. Sie können Ihre Meinung jederzeit ändern, indem Sie dieses Kästchen markieren, oder sich über die Nummer unten an uns wenden. (GIRO-Kartennutzer können eine einmalige Zahlung mit ihrer Karte vornehmen, aber leider ist es nicht möglich, eine wiederkehrende Kartenzahlung einzurichten)',
  recurringMessageNoExistingDebt:
    'Bitte kreuzen Sie dieses Kästchen an, wenn Sie damit einverstanden sind, dass David Lloyd Clubs in Zukunft Zahlungen von dieser Karte einzieht, wenn Ihr Konto nach dem 5. eines jeden Monats unbezahlt bleibt und Sie keine aktive Zahlungsmethode haben. Sie können Ihre Meinung jederzeit ändern, indem Sie dieses Kästchen deaktivieren oder sich unter der unten angegebenen Nummer melden. (GIRO-Kartennutzer können eine einmalige Zahlung mit ihrer Karte vornehmen, aber leider ist es nicht möglich, eine wiederkehrende Kartenzahlung einzurichten)',
  waitingForUpdateCheck: 'Ihre Einstellungen werden aktualisiert, bitte warten Sie...',
  settingsUpdateSuccess: 'Ihre Einstellungen wurden erfolgreich aktualisiert.',
  settingsUpdatedPleaseWait:
    'Es kann einige Minuten dauern, bis diese Änderungen in diesem Portal sichtbar werden.',
  doNotTryUpdateAgain: 'Bitte versuchen Sie nicht, diese Einstellungen erneut zu ändern.',
  loginFormHeader: 'Auf Zahlungsportal einloggen',
  processingPaymentLoadingMessage: 'Ihre Zahlung wird jetzt bearbeitet...',
  processingRecurringSettingsLoadingMessage:
    'Regelmäßige Zahlungsmethode wird eingerichtet, bitte warten...',
  thankYou: 'Vielen Dank',
  thankYouForPayment: 'Vielen Dank für Ihre Zahlung',
  thankYouForRecurring: 'Vielen Dank, dass Sie eine regelmäßige Zahlungsmethode eingerichtet haben',
  somethingWrongWithPayment: 'Etwas ist schief gelaufen bei Ihrer Zahlung.',
  pleaseCheckCardDetails: 'Bitte überprüfen Sie Ihre Kartenangaben und versuchen Sie es erneut.',
  tryAgain: 'Versuchen Sie es erneut',
  errorMessagePayment: 'Bei Ihrer Zahlung ist ein Fehler aufgetreten.',
  logOutTryAgain:
    'Bitte gehen Sie zur Homepage zurück, um den Zahlungsvorgang erneut zu versuchen.',
  privacyPolicy: 'Datenschutz',
  securityPolicy: 'Sicherheit',
  refundPolicy: 'Rückerstattungsrichtlinie',
  termsAndConditions: 'Nutzungsbedingungen',
  saveSettingsDynamicButtonText: 'Speichern & ',
  error404: '404 Fehler',
  pageNotFoundHeader: 'Seite nicht gefunden',
  pageNotFoundMessage:
    'Tut uns Leid, aber die von Ihnen aufgerufene Seite konnte nicht gefunden werden.',
  notFoundTryAgain:
    'Bitte gehen Sie zur Homepage zurück, um den Zahlungsvorgang erneut zu versuchen.',
  home: 'Home',
  close: 'Schließen',
  securityPopupMessage1:
    'Wir bei David Lloyd Leisure verpflichten uns, die Sicherheit und Vertraulichkeit Ihrer Daten zu schützen, indem wir Ihnen eine sichere Transaktionsumgebung bieten.',
  securityPopupMessage2:
    'Unsere Website verwendet die dem Industriestandard entsprechende Secure Sockets Layer (SSL)-Technologie, um die Verschlüsselung persönlicher Daten zu gewährleisten, und wir halten uns erwartungsgemäß an die internationalen PCI-Standards (Payment Card Industry) für Datensicherheit.',
  securityPopupMessage3:
    'Wir haben auch zusätzliche 3D-Sicherheit, die für alle Transaktionen verwendet wird. 3D Secure funktioniert wie ein Chip & Pin und schützt den Karteninhaber, indem es ein geheimes Passwort verlangt. Dadurch kann Ihr Kartenaussteller seine Karteninhaber während der Online-Transaktion vollständig authentifizieren, was die Wahrscheinlichkeit einer betrügerischen Nutzung verringert.',
  securityPopupMessage4:
    'Wir werden, soweit es in unserer Macht steht, alle angemessene Sorgfalt walten lassen, um die Details Ihrer Bestellung und Zahlung sicher aufzubewahren, aber ohne Fahrlässigkeit unsererseits können wir nicht für Verluste haftbar gemacht werden, die Sie erleiden, wenn sich ein Dritter unbefugten Zugang zu Daten verschafft, die Sie beim Zugriff auf diese Website oder bei einer Bestellung auf dieser Website angeben.',
  securityPopupMessage5:
    'Es ist wichtig, dass Sie sich vor unbefugtem Zugriff auf Ihr Passwort und Ihren Computer schützen. Vergewissern Sie sich, dass Sie sich abmelden, wenn Sie die Nutzung eines gemeinsam genutzten Computers beenden.',
  refundPolicyMessage1:
    'Die Rückerstattung erfolgt gemäß den Bestimmungen der Mitgliedschaftsbedingungen, es sei denn, es liegt eine doppelte Zahlung vor; in diesem Fall erfolgt eine vollständige Rückerstattung.',
  refundPolicyMessage2:
    'Rückerstattungen werden innerhalb von 5 Arbeitstagen nach der Benachrichtigung über eine doppelte Zahlung bearbeitet und per Überweisung an Ihre Bank vorgenommen.',
  languagePickerMessage: 'Sprache hier auswählen',
  contactHeader: 'Verwaltung',
};
