import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { LanguageContext } from 'src/context/language-context';
import { translateStaticTextIntoLanguage } from 'src/translators/static-text-translator';

export const PageHeader: React.FC<{}> = ({}) => {
  const { language } = useContext(LanguageContext);
  return (
    <>
      <Helmet title={translateStaticTextIntoLanguage('welcomeBannerHeader', language)} />
    </>
  );
};
