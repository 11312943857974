import React from 'react';
import { Language } from 'src/shared/enums/language';

export type LanguageConfig = {
  language: Language;
  updateLanguage: (language: Language) => void;
};

export const LanguageContext = React.createContext<LanguageConfig>({
  language: Language.EN_GB,
  updateLanguage: () => {},
});
