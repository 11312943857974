import React, { useState } from 'react';
import { LanguageContext } from 'src/context/language-context';
import { Language, getAllLanguages } from 'src/shared/enums/language';
import { getCookie, LANGUAGE_KEY, setCookie } from 'src/helpers/cookie-helper';

type LanguageProviderProps = {
  children: React.ReactChild;
};

const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const languageCookieValue = getCookie(LANGUAGE_KEY) as Language;
  const isValidLanguage = getAllLanguages().includes(languageCookieValue);
  const languageFromCookie = isValidLanguage ? languageCookieValue : Language.EN_GB;

  const [language, setLanguage] = useState<Language>(languageFromCookie);

  const updateLanguageAndSetCookie = (newLanguage: Language) => {
    setCookie(LANGUAGE_KEY, newLanguage);
    setLanguage(newLanguage);
  };
  return (
    <LanguageContext.Provider
      value={{
        language,
        updateLanguage: updateLanguageAndSetCookie,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
