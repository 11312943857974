export const palettes = {
  common: {
    white: '#FFFFFF',
    paleGray: '#F6F6F6',
    lightGray: '#EEEEEE',
    gray: '#CCCCCC',
    footerGray: '#a5a5a5',
    red: '#C41E3D',
    fadedBronze: '#A99995',
    paleGrayBorder: '#b6b3b34d',
    disabledButton: '#a4378963',
    inputFormBackground: '#a8a7a73f',
    inputFormPlaceholder: '#53342c70',
  },
  davidLloyd: {
    accents: {
      normal: '#AF2C8A',
      light: '#E7C0DC',
      dark: '#881E68',
      secondary: '#64D2BB',
    },
    brown: '#53342C',
    lightBrown: '#AF9E9A',
    grayBrown: '#443D3B',
  },
  harbour: {
    accents: {
      normal: '#BD9B60',
      light: '#DDC499',
      dark: '#9E814F',
    },
    gray: '#323E48',
    veryDarkGold: '#4B4130',
    harbourGold: '#BD9B60',
    darkNavy: '#323e48',
  },
  meridian: {
    accents: {
      normal: '#FF5A1F',
      light: '#FCD9BD',
      dark: '#FC440F',
    },
    meridianNavy: '#0B365C',
    lightNavy: '#27303f',
    darkNavy: '#1a202e',
  },
} as const;
