import { Brand } from 'src/shared/enums/brand';
import { Language } from 'src/shared/enums/language';

export const companyName: Partial<Record<Brand, string>> = {
  [Brand.DL_IRELAND]: 'David Lloyd Riverview Operations Ireland Ltd',
  [Brand.DL_SPAIN]: 'David Lloyd Leisure España II SL',
  [Brand.DL_NETHERLANDS]: 'David Lloyd Health & Fitness BV',
  [Brand.DL_FRANCE]: 'David Lloyd Clubs France SAS',
  [Brand.DL_ITALY]: 'David Lloyd Leisure Limited',
  [Brand.DL_GERMANY]: 'David Lloyd Leisure Limited',
  [Brand.DL_BELGIUM]: 'David Lloyd Leisure Operations SPRL',
  [Brand.DL_UK]: 'David Lloyd Leisure Ltd.',
  [Brand.HARBOUR]: 'Harbour Club Ltd.',
  [Brand.MERIDIAN]: 'Meridian Spa & Fitness Deutschland GmbH',
};

export const VATNumber: Partial<Record<Brand, string>> = {
  [Brand.DL_IRELAND]: '9698607P',
  [Brand.DL_SPAIN]: '740 5477 34',
  [Brand.DL_NETHERLANDS]: 'NL807614464 B01',
  [Brand.DL_FRANCE]: '740 5477 34',
  [Brand.DL_ITALY]: '740 5477 34',
  [Brand.DL_GERMANY]: '740 5477 34',
  [Brand.DL_BELGIUM]: 'ESB83632692',
  [Brand.DL_UK]: '740 5477 34',
  [Brand.HARBOUR]: '740 5477 34',
  [Brand.MERIDIAN]: 'DE 226207486',
};

export const addressEU: Partial<Record<Brand, string>> = {
  [Brand.DL_IRELAND]:
    'David Lloyd Leisure Operations Ireland Ltd\n' +
    'Registered in Ireland, Company No. 464260.\n' +
    'Beech Hill, Clonskeagh, Dublin 4.',
  [Brand.DL_SPAIN]: '10ª. Avda. Diagonal, 673-685\n' + '08028 Barcelona\n' + 'España\n' + '\n',
  [Brand.DL_NETHERLANDS]: 'Peter Zuidlaan 30\n' + '5502 NH\n' + 'Veldhoven\n' + 'Nederland\n',
  [Brand.DL_FRANCE]:
    'David Lloyd City Green \n' + '740 route des Planlets \n' + '74140 Veigy-Foncenex, France\n',
  [Brand.DL_ITALY]:
    'Via dello Sport 1, San Felice\n' + 'Peschiera Borromeo\n' + '20068 MILANO, ITALY\n' + '\n',
  [Brand.DL_GERMANY]:
    'David Lloyd Clubs Germany GmbH\n' +
    'Niederstedter Weg 12\n' +
    '61348 Bad Homburg\n' +
    'Deutschland\n',
  [Brand.DL_BELGIUM]: '41 Dréve de Lorraine\n' + '1180 Uccle\n' + 'Belgique\n',
  [Brand.DL_UK]:
    'Registered Company No. 6261643\n' +
    'The Hangar, Mosquito Way, Hatfield Business Park\n' +
    'Hatfield, Hertfordshire, AL10 9AX\n',
  [Brand.HARBOUR]:
    'Registered Company No. 6261643\n' +
    'The Hangar, Mosquito Way, Hatfield Business Park\n' +
    'Hatfield, Hertfordshire, AL10 9AX\n',
  [Brand.MERIDIAN]: 'Wandsbeker Zollstr. 87-89\n' + '22041 Hamburg\n' + 'Deutschland',
};

export const brandPoliciesConfig = {
  [Brand.DL_SPAIN]: {
    privacyPolicyUrl: 'https://www.davidlloyd.es/policy/es-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.es/-/media/david-lloyd/files/es-terms-and-conditions-october-2019.pdf',
  },
  [Brand.DL_BELGIUM]: {
    privacyPolicyUrl: 'https://www.davidlloyd.be/policy/be-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.nl/-/media/david-lloyd/files/be-terms-and-conditions-october-2019.pdf',
  },
  [Brand.DL_UK]: {
    privacyPolicyUrl: 'https://www.davidlloyd.co.uk/policy/privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.co.uk/-/media/david-lloyd/files/uk-terms-and-conditions-october-2019-v2.pdf',
    cancellationPolicyUrl: {
      'en-gb': 'https://www.davidlloyd.co.uk/policy/uk-booking-policy',
    },
  },
  [Brand.DL_FRANCE]: {
    privacyPolicyUrl: 'https://www.davidlloyd.fr/policy/fr-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.fr/-/media/david-lloyd/files/fr-terms-and-conditions-2019.pdf',
  },
  [Brand.DL_GERMANY]: {
    privacyPolicyUrl: 'https://www.davidlloyd.de/policy/de-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.de/-/media/david-lloyd/files/de-terms-and-conditions-october-2019-v2.pdf',
  },
  [Brand.HARBOUR]: {
    privacyPolicyUrl: 'https://www.harbourclub.com/policy/privacy-policy',
    termsAndConditionsUrl:
      'https://www.harbourclub.com/-/media/harbour-club/files/membership-booklet/member-handbook-harbour-clubs.pdf',
    cancellationPolicyUrl: {
      'en-gb':
        'https://www.harbourclub.com/-/media/harbour-club/files/policy-docs/booking-policy-faqs-harbour-may19.pdf?la=en&hash=34A6C9488BB8C04923954E77B2518DA4DE5E460F',
    },
  },
  [Brand.DL_IRELAND]: {
    privacyPolicyUrl: 'https://www.davidlloyd.ie/policy/ie-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.ie/-/media/david-lloyd/files/david-lloyd-ireland-terms-and-conditions-2019.pdf',
  },
  [Brand.DL_ITALY]: {
    privacyPolicyUrl: 'https://www.davidlloyd.it/policy/it-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.it/-/media/david-lloyd/files/it-terms-and-conditions-october-2019.pdf',
  },
  [Brand.DL_NETHERLANDS]: {
    privacyPolicyUrl: 'https://www.davidlloyd.nl/policy/nl-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.nl/-/media/david-lloyd/files/nl-terms-and-conditions-october-2019.pdf',
  },
  [Brand.MERIDIAN]: {
    privacyPolicyUrl: 'https://www.meridianspa.de/de-de/datenschutz',
    termsAndConditionsUrl:
      'https://www.meridianspa.de/allgemeine-geschaeftsbedingungen-meridian-spa-fitness/',
    cancellationPolicyUrl: {
      'en-gb': 'https://www.meridianspa.de/en/cancellation-policy',
      'de-de': 'https://www.meridianspa.de/de-de/cancellation-policy',
    },
  },
  [Brand.DL_SWITZERLAND]: {
    privacyPolicyUrl: 'https://www.davidlloyd.fr/policy/fr-privacy-policy',
    termsAndConditionsUrl:
      'https://www.davidlloyd.fr/-/media/david-lloyd/files/fr-terms-and-conditions-2019.pdf',
  },
};

export const getBrandFromDomainName = () => {
  const domain = window.location.hostname;

  switch (true) {
    case domain.endsWith('davidlloyd.co.uk'):
      return Brand.DL_UK;
    case domain.endsWith('davidlloyd.ie'):
      return Brand.DL_IRELAND;
    case domain.endsWith('davidlloyd.fr'):
      return Brand.DL_FRANCE;
    case domain.endsWith('davidlloyd.de'):
      return Brand.DL_GERMANY;
    case domain.endsWith('davidlloyd.it'):
      return Brand.DL_ITALY;
    case domain.endsWith('davidlloyd.nl'):
      return Brand.DL_NETHERLANDS;
    case domain.endsWith('davidlloyd.ch'):
      return Brand.DL_SWITZERLAND;
    case domain.endsWith('davidlloyd.be'):
      return Brand.DL_BELGIUM;
    case domain.endsWith('davidlloyd.es'):
      return Brand.DL_SPAIN;
    case domain.endsWith('meridianspa.de'):
      return Brand.MERIDIAN;
    case domain.endsWith('harbourclub.com'):
      return Brand.HARBOUR;
    default:
      return Brand.DL_UK;
  }
};

export const getSkinFromCurrentBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.HARBOUR:
      return 'HARBOUR';
    case Brand.MERIDIAN:
      return 'MERIDIAN';
    default:
      return 'DAVID_LLOYD';
  }
};

export const getLogoFromCurrentBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.HARBOUR:
      return require('src/images/white-harbour-logo.png').default;
    case Brand.MERIDIAN:
      return require('src/images/meridian-logo-dark-bg.png').default;
    default:
      return require('src/images/david-lloyd-logo.png').default;
  }
};

export const getFontFamily = () => {
  const domain = window.location.hostname;
  switch (true) {
    case domain.endsWith('meridianspa.de'):
      return 'MuseoSansRounded500';
    case domain.endsWith('harbourclub.com'):
      return 'ITC Avant Garde Pro Bk';
    default:
      return 'inherit';
  }
};

export const getDefaultLanguageForBrand = (brand: Brand) => {
  switch (brand) {
    case Brand.DL_UK:
      return Language.EN_GB;
    case Brand.DL_SPAIN:
      return Language.ES_ES;
    case Brand.DL_NETHERLANDS:
      return Language.NL_BE;
    case Brand.DL_BELGIUM:
      return Language.NL_BE;
    case Brand.DL_ITALY:
      return Language.IT_IT;
    case Brand.DL_FRANCE:
      return Language.FR_BE;
    case Brand.DL_GERMANY:
      return Language.DE_DE;
    case Brand.MERIDIAN:
      return Language.DE_DE;
    case Brand.HARBOUR:
      return Language.EN_GB;
    default:
      return Language.EN_GB;
  }
};

export const contactNumbersByBrand = {
  [Brand.DL_UK]: {
    [Language.EN_GB]: ['0300 303 9531'],
    [Language.FR_BE]: ['0300 303 9531'],
    [Language.ES_ES]: ['0300 303 9531'],
    [Language.DE_DE]: ['0300 303 9531'],
    [Language.CA_ES]: ['0300 303 9531'],
    [Language.IT_IT]: ['0300 303 9531'],
    [Language.NL_BE]: ['0300 303 9531'],
  },
  [Brand.DL_IRELAND]: {
    [Language.EN_GB]: ['1890930911'],
    [Language.FR_BE]: ['1890930911'],
    [Language.ES_ES]: ['1890930911'],
    [Language.DE_DE]: ['1890930911'],
    [Language.CA_ES]: ['1890930911'],
    [Language.IT_IT]: ['1890930911'],
    [Language.NL_BE]: ['1890930911'],
  },
  [Brand.DL_SPAIN]: {
    [Language.EN_GB]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.FR_BE]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.ES_ES]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.DE_DE]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.CA_ES]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.IT_IT]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
    [Language.NL_BE]: [
      'Aravaca 910 38 13 70',
      'Gava Mar 936 45 15 00',
      'Malaga 952 20 72 53',
      'Turo 93 448 91 52',
      'Zaragoza 976 50 67 20',
    ],
  },
  [Brand.DL_NETHERLANDS]: {
    [Language.EN_GB]: ['010-2434710'],
    [Language.FR_BE]: ['010-2434710'],
    [Language.ES_ES]: ['010-2434710'],
    [Language.DE_DE]: ['010-2434710'],
    [Language.CA_ES]: ['010-2434710'],
    [Language.IT_IT]: ['010-2434710'],
    [Language.NL_BE]: ['010-2434710'],
  },
  [Brand.DL_BELGIUM]: {
    [Language.EN_GB]: [
      'Antwerp +32 (0)3 451 30 30',
      'Brussels +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.FR_BE]: [
      'Anvers +32 (0)3 451 30 30',
      'Bruxelles +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.ES_ES]: [
      'Anveres +32 (0)3 451 30 30',
      'Bruselas +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.DE_DE]: [
      'Antwerpen +32 (0)3 451 30 30',
      'Brüssel +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.CA_ES]: [
      'Anvers +32 (0)3 451 30 30',
      'Brussel·les +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.IT_IT]: [
      'Anversa +32 (0)3 451 30 30',
      'Bruxelles +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
    [Language.NL_BE]: [
      'Antwerp +32 (0)3 451 30 30',
      'Brussels +32 (0)2 379 32 00',
      'Sterrebeek +32 (0) 379 32 80',
    ],
  },
  [Brand.DL_ITALY]: {
    [Language.EN_GB]: ['02-7530075'],
    [Language.FR_BE]: ['02-7530075'],
    [Language.ES_ES]: ['02-7530075'],
    [Language.DE_DE]: ['02-7530075'],
    [Language.CA_ES]: ['02-7530075'],
    [Language.IT_IT]: ['02-7530075'],
    [Language.NL_BE]: ['02-7530075'],
  },
  [Brand.DL_FRANCE]: {
    [Language.EN_GB]: ['04 50 94 86 24'],
    [Language.FR_BE]: ['04 50 94 86 24'],
    [Language.ES_ES]: ['04 50 94 86 24'],
    [Language.DE_DE]: ['04 50 94 86 24'],
    [Language.CA_ES]: ['04 50 94 86 24'],
    [Language.IT_IT]: ['04 50 94 86 24'],
    [Language.NL_BE]: ['04 50 94 86 24'],
  },
  [Brand.DL_GERMANY]: {
    [Language.EN_GB]: ['06172-309999'],
    [Language.FR_BE]: ['06172-309999'],
    [Language.ES_ES]: ['06172-309999'],
    [Language.DE_DE]: ['06172-309999'],
    [Language.CA_ES]: ['06172-309999'],
    [Language.IT_IT]: ['06172-309999'],
    [Language.NL_BE]: ['06172-309999'],
  },
  [Brand.MERIDIAN]: {
    [Language.EN_GB]: ['040 6589 20200'],
    [Language.FR_BE]: ['040 6589 20200'],
    [Language.ES_ES]: ['040 6589 20200'],
    [Language.DE_DE]: ['040 6589 20200'],
    [Language.CA_ES]: ['040 6589 20200'],
    [Language.IT_IT]: ['040 6589 20200'],
    [Language.NL_BE]: ['040 6589 20200'],
  },
  [Brand.HARBOUR]: {
    [Language.EN_GB]: ['0300 303 9531'],
    [Language.FR_BE]: ['0300 303 9531'],
    [Language.ES_ES]: ['0300 303 9531'],
    [Language.DE_DE]: ['0300 303 9531'],
    [Language.CA_ES]: ['0300 303 9531'],
    [Language.IT_IT]: ['0300 303 9531'],
    [Language.NL_BE]: ['0300 303 9531'],
  },
  [Brand.DL_SWITZERLAND]: {
    [Language.EN_GB]: ['022 9597900'],
    [Language.FR_BE]: ['022 9597900'],
    [Language.ES_ES]: ['022 9597900'],
    [Language.DE_DE]: ['022 9597900'],
    [Language.CA_ES]: ['022 9597900'],
    [Language.IT_IT]: ['022 9597900'],
    [Language.NL_BE]: ['022 9597900'],
  },
};

export const openingHoursByBrandByLanguage = {
  [Brand.DL_UK]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Thursday 9am to 7pm. Friday 9am to 6pm. Saturday 9am to 5pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Jeudi 0900-1900. Vendredi 0900-1800. Samedi 0900-1700.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a jueves 0900-1900. Viernes 0900-1800. Sábado 0900-1700.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Donnerstag 0900-1900. Freitag 0900-1800. Samstag 0900-1700.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a dijous 0900-1900. Divendres 0900-1800. Dissabte 0900-1700.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al giovedì 0900-1900. Venerdì 0900-1800. Sabato 0900-1700.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot donderdag 0900-1900. Vrijdag 0900-1800. Zaterdag 0900-1700.',
  },
  [Brand.HARBOUR]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Thursday 8am to 8pm. Friday 8am to 6pm. Saturday 10am to 2pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Jeudi 0800-2000. Vendredi 0800-1800. Samedi 1000-1400.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a jueves 0800-2000. Viernes 0800-1800. Sábado 1000-1400.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Donnerstag 0800-2000. Freitag 0800-1800. Samstag 1000-1400.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a dijous 0800-2000. Divendres 0800-1800. Dissabte 1000-1400.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al giovedì 0800-2000. Venerdì 0800-1800. Sabato 1000-1400.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot donderdag 0800-2000. Vrijdag 0800-1800. Zaterdag 1000-1400.',
  },
  [Brand.MERIDIAN]: {
    [Language.EN_GB]: 'Opening Hours: Monday to Friday 9am to 5pm.',
    [Language.FR_BE]: 'Horaires d’ouverture: Lundi au Vendredi 0900-1700.',
    [Language.ES_ES]: 'Horario de apertura: De lunes a Viernes 0900-1700.',
    [Language.DE_DE]: 'Öffnungszeiten: Montag bis Freitag 0900-1700.',
    [Language.CA_ES]: "Horari d'obertura: De dilluns a Divendres 0900-1700.",
    [Language.IT_IT]: 'Orario di apertura: Dal lunedì al Venerdì 0900-1700.',
    [Language.NL_BE]: 'Openingsuren: Maandag tot Vrijdag 0900-1700.',
  },
  [Brand.DL_BELGIUM]: {
    [Language.EN_GB]:
      'Reception opening hours: Monday to Friday 06:30 to 22:00. Weekends and bank holidays 07:30 to 21:00.',
    [Language.FR_BE]:
      'Horaires de la réception : du lundi au vendredi de 6h30 à 22h00. Week-end et jours fériés de 7h30 à 21h00.',
    [Language.ES_ES]:
      'Horario de recepción: Lunes a viernes de 06:30 a 22:00. Sábado y domingo de 07:30 a 21:00.',
    [Language.DE_DE]:
      'Öffnungszeiten der Rezeption: Montag bis Freitag von 06:30 bis 22:00 Uhr. Samstag und Sonntag von 07:30 bis 21:00 Uhr.',
    [Language.CA_ES]:
      'Horari de recepció: de dilluns a divendres de 06:30 a 22:00. Dissabte i diumenge de 07:30 a 21:00.',
    [Language.IT_IT]:
      'Orari di apertura della reception: dal lunedì al venerdì, dalle 06:30 alle 22:00. Sabato e domenica dalle 07:30 alle 21:00.',
    [Language.NL_BE]:
      'Openingstijden receptie: maandag t/m vrijdag van 06:30 tot 22:00 uur. Zaterdag en zondag van 07.30 tot 21.00 uur.',
  },
  [Brand.DL_FRANCE]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Friday 7:30am to 9:30pm. Saturday/Sunday 8am to 7:30pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Vendredi 0730-2130. Samedi/Dimanche 0800-1930.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a Viernes 0730-2130. Sábado/Domingo 0800-1930.',
    [Language.DE_DE]: 'Öffnungszeiten: Montag bis Freitag 0730-2130. Samstag/Sonntag 0800-1930.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a Divendres 0730-2130. Dissabte/Diumenge 0800-1930.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al Venerdì 0730-2130. Sabato/Domenica 0800-1930.',
    [Language.NL_BE]: 'Openingsuren: Maandag tot Vrijdag 0730-2130. Zaterdag/Zondag 0800-1930.',
  },
  [Brand.DL_GERMANY]: {
    [Language.EN_GB]:
      'Opening Hours: Monday 6am to 11pm. Tuesday to Friday 7am to 11pm. Saturday/Sunday 9am to 8 pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi 0600-2300. Mardi au Vendredi 0700-2300. Samedi/Dimanche 0900-2000.',
    [Language.ES_ES]:
      'Horario de apertura: Lunes 0600-2300. De Martes a Viernes 0700-2300. Sábado/Domingo 0900-2000.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag 0600-2300. Dienstag bis Freitag 0700-2300. Samstag/Sonntag 0900-2000.',
    [Language.CA_ES]:
      "Horari d'obertura: Dilluns 0600-2300. De Dimarts a Divendres 0700-2300. Dissabte/Diumenge 0900-2000.",
    [Language.IT_IT]:
      'Orario di apertura: Lunedì 0600-2300. Dal Martedì al Venerdì 0700-2300. Sabato/Domenica 0900-2000.',
    [Language.NL_BE]:
      'Openingsuren: Maandag 0600-2300. Dinsdag tot Vrijdag 0700-2300. Zaterdag/Zondag 0900-2000.',
  },
  [Brand.DL_IRELAND]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Thursday 8am to 8pm. Friday 8am to 6pm. Saturday 10am to 2pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Jeudi 0800-2000. Vendredi 0800-1800. Samedi 1000-1400.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a jueves 0800-2000. Viernes 0800-1800. Sábado 1000-1400.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Donnerstag 0800-2000. Freitag 0800-1800. Samstag 1000-1400.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a dijous 0800-2000. Divendres 0800-1800. Dissabte 1000-1400.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al giovedì 0800-2000. Venerdì 0800-1800. Sabato 1000-1400.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot donderdag 0800-2000. Vrijdag 0800-1800. Zaterdag 1000-1400.',
  },
  [Brand.DL_SWITZERLAND]: {
    [Language.EN_GB]: 'Opening Hours: Monday to Friday 9am to 5pm.',
    [Language.FR_BE]: 'Horaires d’ouverture: Lundi au Vendredi 0900-1700.',
    [Language.ES_ES]: 'Horario de apertura: De lunes a Viernes 0900-1700.',
    [Language.DE_DE]: 'Öffnungszeiten: Montag bis Freitag 0900-1700.',
    [Language.CA_ES]: "Horari d'obertura: De dilluns a Divendres 0900-1700.",
    [Language.IT_IT]: 'Orario di apertura: Dal lunedì al Venerdì 0900-1700.',
    [Language.NL_BE]: 'Openingsuren: Maandag tot Vrijdag 0900-1700.',
  },
  [Brand.DL_SPAIN]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Friday 7:30am to 10:30pm. Saturday 9:00am to 5:00pm. Sunday 9:00am to 3:00pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Vendredi 0730-2230. Samedi 0900-1700. Dimanche 0900-1500.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a Viernes 0730-2230. Sábado 0900-1700. Domingo 0900-1500.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Freitag 0730-2230. Samstag 0900-1700. Sonntag 0900-1500.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a Divendres 0730-2230. Dissabte 0900-1700. Diumenge 0900-1500.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al Venerdì 0730-2230. Sabato 0900-1700. Domenica 0900-1500.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot Vrijdag 0730-2230. Zaterdag 0900-1700. Zondag 0900-1500.',
  },
  [Brand.DL_ITALY]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Friday 7am to 11pm. Saturday 9am to 11pm. Sunday 8am to 7:30pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Vendredi 0700-2300. Samedi 0900-2300. Dimanche 0800-1930.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a Viernes 0700-2300. Sábado 0900-2300. Domingo 0800-1930.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Freitag 0700-2300. Samstag 0900-2300. Sonntag 0800-1930.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a Divendres 0700-2300. Dissabte 0900-2300. Diumenge 0800-1930.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al Venerdì 0700-2300. Sabato 0900-2300. Domenica 0800-1930.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot Vrijdag 0700-2300. Zaterdag 0900-2300. Zondag 0800-1930..',
  },
  [Brand.DL_NETHERLANDS]: {
    [Language.EN_GB]:
      'Opening Hours: Monday to Thursday 9am to 9pm. Friday 9am to 7pm. Saturday 11am to 3pm.',
    [Language.FR_BE]:
      'Horaires d’ouverture: Lundi au Jeudi 0900-2100. Vendredi 0900-1900. Samedi 1100-1500.',
    [Language.ES_ES]:
      'Horario de apertura: De lunes a jueves 0900-2100. Viernes 0900-1900. Sábado 1100-1500.',
    [Language.DE_DE]:
      'Öffnungszeiten: Montag bis Donnerstag 0900-2100. Freitag 0900-1900. Samstag 1100-1500.',
    [Language.CA_ES]:
      "Horari d'obertura: De dilluns a dijous 0900-2100. Divendres 0900-1900. Dissabte 1100-1500.",
    [Language.IT_IT]:
      'Orario di apertura: Dal lunedì al giovedì 0900-2100. Venerdì 0900-1900. Sabato 1100-1500.',
    [Language.NL_BE]:
      'Openingsuren: Maandag tot donderdag 0900-2100. Vrijdag 0900-1900. Zaterdag 1100-1500.',
  },
};
