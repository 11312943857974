import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: 'Actualmente no tienes saldo pendiente asociado a tu cuenta.',
  waitingForLoginDetailsCheck: 'Comprobando sus datos, por favor, espere...',
  loginNotFound:
    'Lo sentimos, no encontramos información relacionada a los datos que nos proporcionaste.',
  checkDetailsTryAgain: 'Por favor compruébalos y intenta otra vez.',
  preparingPaymentLoadingMessage: 'Preparando su pago, por favor, espere...',
  updateRecurringLoadingMessage: 'Preparando sus pagos periódicos, por favor, espere...',
  paymentAccepted: 'Le hemos enviado un email de confirmación',
  recurringAccepted: 'A confirmation email has been sent to you',
  welcomeBannerHeader: 'Pago Online',
  hi: 'Hola',
  balanceOf: 'Tiene',
  onAccount: 'pendientes de pago en su cuenta.',
  payNowButtonText: 'Pagar ahora',
  logoutButtonText: 'Cerrar sesión',
  loginButtonText: 'Iniciar',
  membershipNumberField: 'Número de socio*',
  surnameField: 'Apellidos*',
  saveSettings: 'Guardar ajustes',
  recurringQuestion: '¿Configurar el pago con una tarjeta recurrente?',
  recurringMessage:
    'Marque esta casilla si está conforme con que David Lloyd Clubs utilice de nuevo esta tarjeta en el futuro para cobrar cualquier pago pendiente después de día 5 del mes y en caso de no disponer de un pago automático activo. Podrá cambiar de parecer en cualquier momento desmarcando esta casilla o poniéndose en contacto a través del número inferior.',
  recurringMessageNoExistingDebt:
    'Marca esta casilla si estás de acuerdo con que David Lloyd Clubs debite el pago de esta tarjeta si tu cuenta sigue sin pagar después del día 5 de cada mes, y no tienes un método de pago activo. Puedes cambiar de opinión en cualquier momento desmarcando esta casilla o poniéndote en contacto a través del siguiente número.',
  waitingForUpdateCheck: 'Actualizando sus ajustes, por favor, espere',
  settingsUpdateSuccess: 'Sus ajustes de han actualizado correctamente',
  settingsUpdatedPleaseWait: "Aquests canvis poden tardar uns minuts en veure's al portal.",
  doNotTryUpdateAgain: 'Si us plau, no intentis tornar a canviar la configuració de nou.',
  loginFormHeader: 'Iniciar sesión en el portal de pago',
  processingPaymentLoadingMessage: 'Su pago se está procesando...',
  processingRecurringSettingsLoadingMessage: 'Creando sus pagos periódicos, por favor, espere...',
  thankYou: 'Gracias',
  thankYouForPayment: 'Gracias por su pago',
  thankYouForRecurring: 'Gracias por crear sus pagos periódicos',
  somethingWrongWithPayment: 'Ha habido un error con su pago.',
  pleaseCheckCardDetails: 'Compruebe los datos de su tarjeta y vuelva a intentarlo.',
  tryAgain: 'Vuelva a intentarlo',
  errorMessagePayment: 'Ha habido un error al procesar su pago.',
  logOutTryAgain: 'Vuelva a la página de inicio para volver a iniciar el proceso de pago.',
  privacyPolicy: 'Política de privacidad',
  securityPolicy: 'Seguridad',
  refundPolicy: 'Política de reembolsos',
  termsAndConditions: 'Condiciones',
  saveSettingsDynamicButtonText: 'Guardar y ',
  error404: '404 Error',
  pageNotFoundHeader: 'Página no encontrada',
  pageNotFoundMessage: 'Lo sentimos pero no hemos encontrado la página que ha solicitado.',
  notFoundTryAgain: 'Vuelva a la página de inicio para volver a iniciar el proceso de pago.',
  home: 'Inicio',
  close: 'Tancar',
  securityPopupMessage1:
    "A David Lloyd Leisure, ens comprometem a protegir la seguretat i confidencialitat de les teves dades a través d'un entorn segur a les transaccions.",
  securityPopupMessage2:
    'La nostra pàgina web fa servir la tecnologia Secure Sockets Layer (SSL) estàndard per a encriptar la informació personal i, com pots esperar, ens adherim als estàndars internacionals de seguretat de dades PCI (payments card industry)',
  securityPopupMessage3:
    "A més, tenim seguretat 3D addicional per a totes les transaccions. 3D Secure actúa com un Chip&Pin i protegeix el titular de la targeta demanant una contrassenya secreta. Això permet a la entitat bancària autentificar completament els titular de la targeta durant la transacció online, reduïnt la possibilitat d'usos fraudulents.",
  securityPopupMessage4:
    "Fem tot el que podem per tal de mantenir les dades de la teva transacció i del teu pagament segurs, però en cas que no hi hagi negligència per la nostra part, no podem fer-nos responsables de cap pèrdua que puguis patir si un tercer obté accès no autoritzat a qualsevol dada que proporcionis en accedir o realitzar una transacció des d'aquest web.",
  securityPopupMessage5:
    "És important que et protegeixis davant els accessos no autoritzats a la teva contrassenya i usuari. Assegura't de tancar la sessió quan acabis d'utilitzar un ordinador compartit.",
  refundPolicyMessage1:
    "Les devolucions s'emetran segons els termes i condicions del teu contracte, excepte que es tracti d'un pagament duplicat, que es retornarà completament.",
  refundPolicyMessage2:
    'Les devolucions es processaran en un plaç de 5 dies laborables des de la notificació del pagament duplicat i es faran al teu compte mitjançant transferència bancària.',
  languagePickerMessage: 'Escoja su idioma aquí',
  contactHeader: 'Atención al Socio',
};
