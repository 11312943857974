import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: "Il n'y a pas de solde en cours sur votre compte.",
  waitingForLoginDetailsCheck:
    'Veuillez patienter, vos coordonnées sont en cours de confirmation...',
  loginNotFound: 'Malheureusement, nous ne trouvons aucune information sur ce compte.',
  checkDetailsTryAgain: 'Veuillez vérifier vos informations et réessayer.',
  preparingPaymentLoadingMessage:
    'Veuillez patienter, nous nous préparons à recevoir votre paiement...',
  updateRecurringLoadingMessage:
    'Veuillez patienter, nous préparation la configuration de vos mensualités...',
  paymentAccepted: 'Un e-mail de reçu vous a été envoyé.',
  recurringAccepted: 'Un email de confirmation vous a été envoyé',
  welcomeBannerHeader: 'Paiement en ligne',
  hi: 'Bonjour',
  balanceOf: 'Il y a un solde impayé de',
  onAccount: 'sur votre compte.',
  payNowButtonText: 'Payer maintenant',
  logoutButtonText: 'Se déconnecter',
  loginButtonText: 'Se connecter',
  membershipNumberField: 'Numéro d’abonnement*',
  surnameField: 'Nom*',
  saveSettings: 'Enregistrer ces paramètres',
  recurringQuestion: 'Configurer un paiement récurrent par carte ?',
  recurringMessage:
    "Veuillez cocher cette case si vous acceptez que David Lloyd Clubs prélève le paiement de cette carte à l'avenir au cas où votre compte resterait impayé après le 5 du mois et que vous n'avez pas de mode de paiement actif. Vous pouvez changer d'avis à tout moment en décochant cette case, ou en nous contactant via le numéro ci-dessous.",
  recurringMessageNoExistingDebt:
    "Veuillez cocher cette case si vous acceptez que David Lloyd Clubs prélève le paiement de cette carte à l'avenir au cas où votre compte resterait impayé après le 5 du mois et que vous n'avez pas de mode de paiement actif. Vous pouvez changer d'avis à tout moment en décochant cette case, ou en nous contactant via le numéro ci-dessous.",
  waitingForUpdateCheck: 'Veuillez patienter, la mise à jour de vos paramètres est en cours...',
  settingsUpdateSuccess: 'Vos paramètres ont bien été mis à jour',
  settingsUpdatedPleaseWait: "L'affichage de ces changements peut prendre quelques minutes.",
  doNotTryUpdateAgain: "N'essayez pas de modifier à nouveau ces paramètres.",
  loginFormHeader: 'Se connecter au portail de paiement',
  processingPaymentLoadingMessage: 'Votre paiement est en cours de traitement...',
  processingRecurringSettingsLoadingMessage:
    'Veuillez patienter, nous configurons vos mensualités...',
  thankYou: 'Merci',
  thankYouForPayment: 'Merci pour votre paiement',
  thankYouForRecurring: "Merci d'avoir configuré le paiement mensuel",
  somethingWrongWithPayment: "Une erreur s'est produite avec votre paiement.",
  pleaseCheckCardDetails: 'veuillez vérifier les informations de votre carte et essayer à nouveau.',
  tryAgain: 'Essayer à nouveau',
  errorMessagePayment: 'Une erreur s’est produite lors de la transaction.',
  logOutTryAgain: 'Veuillez revenir à la page d’accueil pour recommencer votre paiement du début.',
  privacyPolicy: 'Politique de confidentialité',
  securityPolicy: 'Sécurité',
  refundPolicy: 'Politique de remboursement',
  termsAndConditions: 'Conditions générales',
  saveSettingsDynamicButtonText: 'Enregistrer et ',
  error404: '404 Erreur',
  pageNotFoundHeader: 'La page cherchée n’a pas été trouvée',
  pageNotFoundMessage: 'Nous sommes désolés, mais la page que vous avez demandée est introuvable. ',
  notFoundTryAgain:
    'Veuillez revenir à la page d’accueil pour recommencer votre paiement du début.',
  home: 'Page d’accueil',
  securityPopupMessage1:
    "David Lloyd Leisure s'est engagé à protéger la sécurité et la confidentialité de vos informations, en vous offrant un environnement transactionnel sécurisé.",
  securityPopupMessage2:
    'Notre site Web utilise la technologie SSL (Secure Sockets Layer) de norme industrielle pour permettre le chiffrement des informations personnelles. Nous respectons les normes de conformité internationales PCI (payment card industry, industrie des cartes de paiement) en matière de sécurité des données.',
  securityPopupMessage3:
    "Nous disposons également d'une sécurité 3D supplémentaire qui est utilisée pour toutes les transactions. 3D Secure fonctionne comme Chip & Pin et protège le détenteur de la carte en lui demandant un mot de passe secret. Ainsi, l'émetteur de votre carte peut assurer l'authentification intégrale de ses détenteurs de carte durant la transaction en ligne, ce qui réduit ainsi le risque d'usage frauduleux.",
  securityPopupMessage4:
    "Nous veillerons avec une attention raisonnable, dans la mesure de notre capacité, à assurer la protection des détails de votre commande et de votre paiement, mais en l'absence of négligence de notre part, nous ne pouvons pas être tenus pour responsables de toute perte éventuelle en cas d'accès non autorisé par un tiers aux données que vous fournissez lors de l'accès ou du passage d'une commande depuis ce site Web.",
  securityPopupMessage5:
    "Il est important que vous vous protégiez contre tout accès non autorisé à votre mot de passe et à votre ordinateur. Veillez à vous déconnecter lorsque vous avez fini d'utiliser un ordinateur partagé.",
  refundPolicyMessage1:
    "Les remboursements seront effectués conformément aux dispositions des conditions d'affiliation, sauf s'il y a eu un double paiement. Dans ce cas, un remboursement intégral sera effectué.",
  refundPolicyMessage2:
    "Les remboursements seront traités dans les 5 jours ouvrables suivant la notification d'un double paiement et seront effectués par virement bancaire sur votre compte.",
  close: 'Fermer',
  languagePickerMessage: 'Choisissez votre langue ici',
  contactHeader: 'Administration',
};
