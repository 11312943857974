import { css } from '@emotion/react';
import { navigate } from 'gatsby-link';
import React, { useContext } from 'react';
import { LanguageContext } from 'src/context/language-context';
import { UserContext } from 'src/context/user-context';
import { getColorScheme } from 'src/styling/colors/colors';
import { mediumMobileThreshold } from 'src/styling/customStyles';
import { staticTextLookupByLanguage } from 'src/translators/static-text-translator';

export const LogoutUserButton: React.FC<{}> = ({}) => {
  const { currentUser, logout } = useContext(UserContext);
  const { language } = useContext(LanguageContext);

  const logOutUser = () => {
    logout();
    localStorage.clear();
    navigate('/');
  };

  const logoutButtonStyle = css`
    background-color: ${getColorScheme().background};
    color: ${getColorScheme().uiElement};
    background-color: ${getColorScheme().headerBackground};
    border: none;
    border-radius: 1px;
    height: 1.5em;
    margin-top: 3px;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;

    @media (max-width: ${mediumMobileThreshold}) {
      font-size: 11px;
    }
  `;

  return currentUser?.membershipNumber ? (
    <button type="button" css={logoutButtonStyle} onClick={logOutUser}>
      {staticTextLookupByLanguage[language].logoutButtonText}
    </button>
  ) : (
    <></>
  );
};

export default LogoutUserButton;
