import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: Partial<StaticTextLookup> = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: 'Er is geen openstaand saldo in je account.',
  waitingForLoginDetailsCheck: 'Even wachten terwijl we uw gegevens controleren...',
  loginNotFound: 'Helaas vinden we geen informatie voor deze account.',
  checkDetailsTryAgain: 'Controleer je gegevens en probeer het nog eens.',
  preparingPaymentLoadingMessage: 'Uw betaling wordt voorbereid, even geduld...',
  updateRecurringLoadingMessage: 'Uw terugkerende betalingen wordt voorbereid, even geduld...',
  paymentAccepted: 'Een ontvangstbewijs werd u per email toegezonden.',
  recurringAccepted: 'Er werd u een bevestigingsmail gestuurd',
  welcomeBannerHeader: 'Online Betaling',
  hi: 'Hallo',
  balanceOf: 'Uw rekening toont een balans van',
  onAccount: '',
  payNowButtonText: 'Betaal nu',
  logoutButtonText: 'Log Out',
  loginButtonText: 'Log In',
  membershipNumberField: 'Lidnummer*',
  surnameField: 'Achternaam*',
  saveSettings: 'Bewaar instellingen',
  recurringQuestion: 'Terugkerende kaartbetaling instellen?',
  recurringMessage:
    'Gelieve dit vakje aan te vinken als u akkoord gaat dat David Lloyd Clubs uw betaling in de toekomst van deze kaart neemt indien uw rekening niet is vereffend binnen vijf dagen van iedere maand als u geen actieve betaalmethode hebt. U kan op ieder ogenblik van idee veranderen door dit vakje terug af te vinken, of ons te contacteren via onderstaand telefoonnummer. (Gebruikers van Ideal kunnen een eenmalige betaling doen, maar helaas kunt u geen periodieke betaling instellen)',
  recurringMessageNoExistingDebt:
    'Kruis dit vakje aan als u ermee akkoord gaat dat David Lloyd Clubs in de toekomst betalingen van deze kaart afhaalt als uw rekening na de 5de van elke maand onbetaald blijft en u geen actieve betalingsmethode hebt. U kunt op elk moment van gedachten veranderen door dit vakje uit te vinken, of door contact op te nemen via het onderstaande nummer. (Gebruikers van Ideal kunnen een eenmalige betaling doen, maar helaas kunt u geen periodieke betaling instellen)',
  waitingForUpdateCheck: 'Even wachten terwijl uw instellingen worden aangepast...',
  settingsUpdateSuccess: 'Uw instellingen werden succesvol aangepast.',
  settingsUpdatedPleaseWait: 'Het kan een paar minuten duren voor deze wijzigingen zichtbaar zijn.',
  doNotTryUpdateAgain: 'Probeer deze instellingen niet opnieuw te wijzigen.',
  loginFormHeader: 'Log in op het betalingsportaal',
  processingPaymentLoadingMessage: 'Uw betaling wordt nu verwerkt...',
  processingRecurringSettingsLoadingMessage:
    'Uw terugkerende betalingen worden ingesteld, even geduld...',
  thankYou: 'Bedankt',
  thankYouForPayment: 'Bedankt voor uw betaling',
  thankYouForRecurring: 'Bedankt voor het instellen van terugkerende betalingen',
  somethingWrongWithPayment: 'Er is iets foutgelopen met uw betalingen.',
  pleaseCheckCardDetails: 'Controleer uw kaartgegevens en probeer het opnieuw.',
  tryAgain: 'Probeer opnieuw',
  errorMessagePayment: 'Er heeft zich een fout voorgedaan tijdens het verwerken van uw betaling.',
  logOutTryAgain: 'Keer terug naar de homepagina en start uw betalingstraject opnieuw.',
  privacyPolicy: 'Privacybeleid',
  securityPolicy: 'Veiligheid',
  refundPolicy: 'Terugbetalingsbeleid',
  termsAndConditions: "T&C's",
  saveSettingsDynamicButtonText: 'Bewaar & ',
  error404: '404 Fout',
  pageNotFoundHeader: 'Pagina niet gevonden',
  pageNotFoundMessage: 'Het spijt ons, maar de pagina die u zocht werd niet gevonden.',
  notFoundTryAgain: 'Keer terug naar de homepagina en start uw betalingstraject opnieuw.',
  home: 'Home',
  securityPopupMessage1:
    'David Lloyd Leisure heeft de veiligheid en vertrouwelijkheid van uw informatie hoog in het vaandel staan en wij bieden om deze reden een beveiligde transactie omgeving.',
  securityPopupMessage2:
    'Onze website maakt gebruik van industriestandaard Secure Sockets Layer (SSL) technologie om uw persoonsgegevens te versleutelen. Wij houden ons uiteraard aan de internationale PCI (payment card industrie) normen voor gegevensbeveiliging.',
  securityPopupMessage3:
    'Wij maken bovendien gebruik van extra 3D beveiliging die gebruikt wordt voor alle transacties. 3D Secure werkt als een Pin & Chipknip transactie en beschermt de kaarthouder door hem/haar om een veilig wachtwoord te vragen. Op deze manier kan de kaartuitgever de identiteit van de kaarthouders tijdens de online transactie valideren om het risico op frauduleus gebruik te verminderen.',
  securityPopupMessage4:
    'Wij nemen alle redelijke voorzorgsmaatregelen om de gegevens van uw order en betaling veilig te houden maar kunnen met uitsluiting van nalatigheid onzer zijde niet aansprakelijk worden gesteld voor eventuele verliezen indien een derde ongeautoriseerde toegang tot de door u verstrekte gegevens verkrijgt tijdens het bezichtigen of bestellen via deze website.',
  securityPopupMessage5:
    'Het is belangrijk dat u ongeautoriseerde toegang tot uw computer voorkomt en uw wachtwoord geheim houdt. Het is belangrijk dat u zich afmeldt nadat u een gedeelde computer hebt gebruikt.',
  refundPolicyMessage1:
    'Terugbetalingen worden gedaan volgens de bepalingen van de lidmaatschapsvoorwaarden, tenzij er sprake is van een dubbele betaling. In dat geval zal een volledige terugbetaling gebeuren.',
  refundPolicyMessage2:
    'Terugbetalingen worden verwerkt binnen 5 werkdagen na kennisgeving van een dubbele betaling en worden overgemaakt naar je bank via een overschrijving.',
  close: 'Sluiten',
  languagePickerMessage: 'Kies hier uw taal',
  contactHeader: 'Administratie',
};
