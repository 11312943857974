import { StaticTextLookup } from '../static-text-lookup';

export const staticTextLookup: StaticTextLookup = {
  pageTitle: 'David Lloyd Payments',
  noOutstandingBalance: "There's no outstanding balance on your account.",
  waitingForLoginDetailsCheck: 'Checking your details, please wait...',
  loginNotFound: 'Unfortunately we could not any information for the account provided.',
  checkDetailsTryAgain: 'Please check your details before trying again.',
  preparingPaymentLoadingMessage: 'Preparing to take your payment, please wait...',
  updateRecurringLoadingMessage: 'Preparing to set up your recurring payments, please wait...',
  paymentAccepted: 'An email receipt has been sent to you',
  recurringAccepted: 'A confirmation email has been sent to you',
  welcomeBannerHeader: 'Online Payment',
  hi: 'Hi',
  balanceOf: "There's a balance of",
  onAccount: 'outstanding on your account.',
  payNowButtonText: 'Pay Now',
  logoutButtonText: 'Log Out',
  loginButtonText: 'Log In',
  membershipNumberField: 'Membership number*',
  surnameField: 'Surname*',
  saveSettings: 'Save Settings',
  recurringQuestion: 'Set up recurring card payment?',
  recurringMessage:
    'Please tick this box if you are happy for David Lloyd Clubs to take payment from this card again in the future if your account remains unpaid after the 5th of each month and you do not have an active payment method. You can change your mind at anytime by unticking this box, or by getting in touch via the number below.',
  recurringMessageNoExistingDebt:
    'Please tick this box if you are happy for David Lloyd Clubs to take payment from this card in the future if your account remains unpaid after the 5th of each month and you do not have an active payment method. You can change your mind at anytime by unticking this box, or by getting in touch via the number below.',
  waitingForUpdateCheck: 'Updating your settings, please wait...',
  settingsUpdateSuccess: 'Your settings have successfully been updated.',
  settingsUpdatedPleaseWait:
    'It can take up to a few minutes for these changes to be reflected in this portal.',
  doNotTryUpdateAgain: 'Please do not attempt to change these settings again.',
  loginFormHeader: 'Log in to payment portal',
  processingPaymentLoadingMessage: 'Your payment is now being processed...',
  processingRecurringSettingsLoadingMessage: 'Setting up your recurring payments, please wait...',
  thankYou: 'Thank you',
  thankYouForPayment: 'Thank you for your payment',
  thankYouForRecurring: 'Thank you for setting up recurring payments',
  somethingWrongWithPayment: 'Something went wrong with your payment.',
  pleaseCheckCardDetails: 'Please check your card details and try again.',
  tryAgain: 'Try again',
  errorMessagePayment: 'An error occured when processing your payment.',
  logOutTryAgain: 'Please relaunch this portal and try again.',
  privacyPolicy: 'Privacy policy',
  securityPolicy: 'Security',
  refundPolicy: 'Refund Policy',
  termsAndConditions: 'T&Cs',
  saveSettingsDynamicButtonText: 'Save & ',
  error404: '404 Error',
  pageNotFoundHeader: 'Page not found',
  pageNotFoundMessage: "We're sorry, but the page you requested could not be found.",
  notFoundTryAgain: 'Please return home to start your payment journey again.',
  home: 'Home',
  securityPopupMessage1:
    'At David Lloyd Leisure we are committed to protecting the security and confidentiality of your information, by providing you with a secure transaction environment.',
  securityPopupMessage2:
    'Our website uses industry-standard Secure Sockets Layer (SSL) technology to provide encryption of personal information and as you would expect we adhere to international PCI (payment card industry) compliance standards for data security.',
  securityPopupMessage3:
    'We also have additional 3D security which is used for all transactions. 3D Secure acts like a Chip & Pin and protects the card holder by asking for a secret password. This allows your card issuer the ability to fully authenticate its cardholders during the online transaction; thereby reducing the likelihood of fraudulent usage.',
  securityPopupMessage4:
    'We will take all reasonable care, in so far as it is in our power to do so, to keep the details of your order and payment secure, but in the absence of negligence on our part we cannot be held liable for any loss you may suffer if a third party procures unauthorised access to any data you provide when accessing or ordering from this Website.',
  securityPopupMessage5:
    'It is important for you to protect against unauthorised access to your password and to your computer. Be sure to sign off when you finish using a shared computer.',
  refundPolicyMessage1:
    'Refunds will be issued as per the provisions of the membership terms and conditions unless there has been a duplicate payment, and then there will be a full refund.',
  refundPolicyMessage2:
    'Refunds will be processed within 5 working days of notification of a duplicate payment and will be made to your bank through a bank transfer.',
  close: 'Close',
  languagePickerMessage: 'Choose your language here',
  contactHeader: 'Central Membership',
};
