export const LANGUAGE_KEY = 'language';

export const getCookie = (key: string) => {
  if (typeof document !== 'undefined') {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + key + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
};

export const setCookie = (key: string, value: string | number) => {
  if (typeof document !== 'undefined') {
    document.cookie = `${key}=${value}`;
  }
};

export function resetAllCookies() {
  if (typeof document !== 'undefined') {
    document.cookie.split(';').forEach((cookie) => {
      const parts = cookie.split('=');
      if (parts.length === 2) {
        const key = parts.shift();
        resetCookie(key);
      }
    });
  }
}

export function resetCookie(key: string) {
  if (typeof document !== 'undefined') {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
