import * as schemes from './schemes';

export function getColorScheme(): schemes.ColorScheme {
  const hostname = window.location.hostname;

  if (hostname.endsWith('harbourclub.com')) {
    return schemes.harbour;
  } else if (hostname.endsWith('meridianspa.de')) {
    return schemes.meridian;
  }
  return schemes.davidLloyd;
}
