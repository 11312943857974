import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { BrandContext } from 'src/context/brand-context';
import { LanguageContext } from 'src/context/language-context';
import { contactNumbersByBrand, openingHoursByBrandByLanguage } from 'src/helpers/brand-helper';
import { smallMobileThreshold } from 'src/styling/customStyles';
import { staticTextLookupByLanguage } from 'src/translators/static-text-translator';

export const ContactInfoFooter: React.FC<{}> = ({}) => {
  const { brand } = useContext(BrandContext);
  const { language } = useContext(LanguageContext);
  const contactInfoFooterStyle = css`
    font-size: 11px;

    p {
      text-align: center;
      margin: 6px;
    }

    h3 {
      text-align: center;
      margin: 6px;
    }

    @media (min-width: ${smallMobileThreshold}) {
      font-size: 13px;
    }
  `;

  return (
    <div css={contactInfoFooterStyle}>
      <h3>{staticTextLookupByLanguage[language].contactHeader}</h3>
      {contactNumbersByBrand[brand][language].map((contactNumber) => {
        return (
          <p key={contactNumber} style={{ margin: '0px' }}>
            {contactNumber}
          </p>
        );
      })}
      <br />
      <p>{openingHoursByBrandByLanguage[brand][language]}</p>
    </div>
  );
};

export default ContactInfoFooter;
