import { palettes } from './palettes';

type ColorSchemeKey =
  | 'uiElement'
  | 'inertElement'
  | 'background'
  | 'overlay'
  | 'highlight'
  | 'white'
  | 'disabledElement'
  | 'slightlyFancyText'
  | 'guideline'
  | 'smallPrint'
  | 'hoverElement'
  | 'fontColor'
  | 'policyFooter'
  | 'policyText'
  | 'footerBackground'
  | 'headerBackground';

export type ColorScheme = { [K in ColorSchemeKey]: string };

const common = {
  guideline: palettes.common.lightGray,
  white: palettes.common.white,
  background: palettes.common.paleGray,
  inertElement: palettes.common.gray,
  smallPrint: palettes.common.fadedBronze,
};

export const davidLloyd: ColorScheme = {
  ...common,
  uiElement: palettes.davidLloyd.accents.normal,
  disabledElement: palettes.davidLloyd.accents.light,
  hoverElement: palettes.davidLloyd.accents.dark,
  overlay: palettes.davidLloyd.grayBrown,
  highlight: palettes.davidLloyd.accents.normal,
  slightlyFancyText: palettes.davidLloyd.brown,
  fontColor: palettes.davidLloyd.brown,
  policyFooter: palettes.common.footerGray,
  policyText: palettes.common.footerGray,
  footerBackground: palettes.common.paleGray,
  headerBackground: palettes.common.paleGray,
};

export const harbour: ColorScheme = {
  ...common,
  uiElement: palettes.harbour.accents.normal,
  disabledElement: palettes.harbour.accents.light,
  hoverElement: palettes.harbour.accents.dark,
  overlay: palettes.harbour.gray,
  highlight: palettes.harbour.accents.normal,
  slightlyFancyText: palettes.harbour.harbourGold,
  fontColor: palettes.harbour.darkNavy,
  policyFooter: palettes.harbour.harbourGold,
  policyText: palettes.common.white,
  footerBackground: palettes.harbour.darkNavy,
  headerBackground: palettes.harbour.darkNavy,
};

export const meridian: ColorScheme = {
  ...common,
  uiElement: palettes.meridian.accents.normal,
  disabledElement: palettes.meridian.accents.light,
  hoverElement: palettes.meridian.accents.dark,
  overlay: palettes.harbour.gray,
  highlight: palettes.meridian.accents.normal,
  slightlyFancyText: palettes.meridian.meridianNavy,
  fontColor: palettes.meridian.meridianNavy,
  policyFooter: palettes.meridian.lightNavy,
  policyText: palettes.common.white,
  footerBackground: palettes.meridian.lightNavy,
  headerBackground: palettes.common.paleGray,
};
