import React, { useState } from 'react';
import './global.css';
import './fonts/fonts.css';
import UserProvider from '../../context/user-provider';
import { PageHeader } from '../page-header';
import { zIndexes } from 'src/styling/zIndexes';
import { getColorScheme } from 'src/styling/colors/colors';
import LanguageProvider from 'src/context/language-provider';
import WelcomeBanner from '../common/welcome-banner';
import Footer from '../common/footer';
import { palettes } from 'src/styling/colors/palettes';
import BrandProvider from 'src/context/brand-provider';
import { getFontFamily } from 'src/helpers/brand-helper';
import { PolicyPopup } from '../common/policy-popup';

type LayoutProps = {
  children: React.ReactChild;
};

const isBrowser = typeof window !== 'undefined';

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [showSecurityPopup, setShowSecurityPopup] = useState(false);
  const [showRefundPopup, setShowRefundPopup] = useState(false);

  const toggleSecurityPopup = () => setShowSecurityPopup(!showSecurityPopup);
  const toggleRefundPopup = () => setShowRefundPopup(!showRefundPopup);

  return (
    isBrowser && (
      <BrandProvider>
        <UserProvider>
          <LanguageProvider>
            <div className="window-wrapper">
              <div
                id="payment-portal-global-container"
                css={{
                  position: 'fixed',
                  height: '100vh',
                  width: '100vw',
                  overflow: 'scroll',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  backgroundColor: getColorScheme().background,
                  color: getColorScheme().fontColor,
                  zIndex: zIndexes.normal,
                  alignContent: 'center',
                  fontFamily: getFontFamily(),
                }}
              >
                <div>
                  <PageHeader />
                  <WelcomeBanner />
                  <div
                    id="payment-portal-app-container"
                    css={{
                      width: '100%',
                      height: 'flex',
                      alignContent: 'center',
                      borderTop: `${palettes.common.paleGrayBorder} solid 1px`,
                      maxWidth: '450px',
                      margin: '0 auto',
                      padding: '0 1rem',
                    }}
                  >
                    {children}
                  </div>
                </div>
                <PolicyPopup
                  showPopup={showSecurityPopup}
                  togglePopup={toggleSecurityPopup}
                  policyType="securityPolicy"
                />
                <PolicyPopup
                  showPopup={showRefundPopup}
                  togglePopup={toggleRefundPopup}
                  policyType="refundPolicy"
                />
                <Footer
                  toggleSecurityPopup={toggleSecurityPopup}
                  toggleRefundPopup={toggleRefundPopup}
                />
              </div>
            </div>
          </LanguageProvider>
        </UserProvider>
      </BrandProvider>
    )
  );
};

export default Layout;
