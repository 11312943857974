export enum Language {
  EN_GB = 'en-gb',
  ES_ES = 'es-es',
  CA_ES = 'ca',
  NL_BE = 'nl-be',
  FR_BE = 'fr-be',
  IT_IT = 'it-it',
  DE_DE = 'de-de',
}

export const getAllLanguages = () => {
  return [
    Language.EN_GB,
    Language.FR_BE,
    Language.NL_BE,
    Language.DE_DE,
    Language.IT_IT,
    Language.ES_ES,
    Language.CA_ES,
  ];
};
