import { css } from '@emotion/react';
import React, { useContext } from 'react';
import { BrandContext } from 'src/context/brand-context';
import { LanguageContext } from 'src/context/language-context';
import { getLogoFromCurrentBrand } from 'src/helpers/brand-helper';
import { getColorScheme } from 'src/styling/colors/colors';
import { palettes } from 'src/styling/colors/palettes';
import { largeMobileThreshold, mediumMobileThreshold } from 'src/styling/customStyles';
import { staticTextLookupByLanguage } from 'src/translators/static-text-translator';
import LogoutUserButton from '../logout-user-button';

export const WelcomeBanner: React.FC<{}> = ({}) => {
  const { language } = useContext(LanguageContext);
  const { brand } = useContext(BrandContext);

  const welcomeBannerContainer = css`
    display: flex;
    align-items: center;
    margin: 0px 0px;
    flex: 1 0 auto;
    height: 5rem;
    z-index: 2
    width: 100%;
    padding: 1rem;
    overflow: hidden;
    text-align: center;
    justify-content: space-between;
    box-shadow: 0px 10px 20px 0px rgb(0 0 0 / 5%);
    background-color: ${getColorScheme().headerBackground};
  `;

  const welcomeHeader = css`
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 20px;
    padding-left: 0.5rem;
    display: inline-block;
    color: ${getColorScheme().slightlyFancyText};

    @media (max-width: ${mediumMobileThreshold}) {
      font-size: 14px;
    }
  `;

  const logoContainer = css`
    padding: 0.5rem;
    flex-shrink: 0;
    width: 6rem;
    border-right: ${palettes.common.paleGrayBorder} solid 1px;

    @media (min-width: ${largeMobileThreshold}) {
      width: 8.7rem;
    }
  `;

  return brand && language ? (
    <div css={welcomeBannerContainer}>
      <div css={logoContainer}>
        <img
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
            verticalAlign: 'middle',
          }}
          src={getLogoFromCurrentBrand(brand)}
          alt="The David Lloyd Clubs logo"
        />
      </div>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        <div css={welcomeHeader}>{staticTextLookupByLanguage[language].welcomeBannerHeader}</div>
      </div>
      <div css={{ flex: 1 }}></div>
      <div css={{ alignSelf: 'center', paddingBottom: '5px' }}>
        <LogoutUserButton />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default WelcomeBanner;
