import React, { useState } from 'react';
import { UserContext, UserDefault, UserType } from './user-context';

type UserProviderProps = {
  children: React.ReactChild;
};

const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserType>();

  //API call to confirm login and get balance info
  //Merge to one function?
  const updateUser = (newUser: UserType) => {
    setUser(newUser);
  };

  const logout = () => setUser(UserDefault);

  return (
    <UserContext.Provider
      value={{
        currentUser: user,
        updateCurrentUser: updateUser,
        logout: logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
